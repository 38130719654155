import type { Suit } from "@hanabi-live/game";
import { assertDefined } from "complete-common";

type DrawFunction = (
  ctx: CanvasRenderingContext2D,
  colors?: readonly string[],
) => void;

const SCALE_FACTOR = 3;

const shapeFunctions = new Map<string, DrawFunction>();

shapeFunctions.set("hanabiRed", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-40.903_844, -80.253_155);
  ctx.moveTo(47.530_531, 88.392_69);
  ctx.bezierCurveTo(
    29.757_962,
    90.070_21,
    31.193_616,
    78.987_85,
    34.277_276,
    72.111_26,
  );
  ctx.bezierCurveTo(
    52.049_847,
    70.433_711,
    50.616_495,
    81.5161,
    47.530_531,
    88.392_69,
  );
  ctx.moveTo(38.201_088, 59.405_974);
  ctx.bezierCurveTo(
    36.488_459,
    59.405_974,
    35.161_286,
    62.901_259,
    34.279_579,
    66.242_17,
  );
  ctx.bezierCurveTo(
    11.602_243,
    72.595_989,
    17.005_567,
    101.105_07,
    43.609_027,
    101.100_33,
  );
  ctx.bezierCurveTo(
    45.321_658,
    101.100_33,
    46.646_522,
    97.604_97,
    47.530_531,
    94.261_78,
  );
  ctx.bezierCurveTo(
    70.205_57,
    87.912_67,
    64.799_917,
    59.401_233,
    38.201_088,
    59.405_974,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("hanabiYellow", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-58.029_065, -70.265_748);
  ctx.moveTo(48.471_374, 76.245_007);
  ctx.bezierCurveTo(
    52.478_308,
    74.048_764,
    57.340_783,
    70.758_733,
    60.960_015,
    66.9381,
  );
  ctx.bezierCurveTo(
    60.918_765,
    71.616_441,
    61.423_545,
    77.451_433,
    63.024_126,
    82.374_519,
  );
  ctx.bezierCurveTo(
    59.019_361,
    79.968_19,
    53.639_232,
    77.395_132,
    48.471_374,
    76.245_007,
  );
  ctx.moveTo(78.213_664, 83.015_659);
  ctx.bezierCurveTo(
    74.748_208,
    79.849_062,
    67.217_334,
    70.373_217,
    64.219_708,
    62.721_08,
  );
  ctx.bezierCurveTo(
    64.353_955,
    62.493_95,
    64.505_635,
    62.265_928,
    64.629_079,
    62.034_484,
  );
  ctx.bezierCurveTo(
    64.629_079,
    62.034_484,
    66.385_639,
    60.000_655,
    63.054_465,
    55.839_957,
  );
  ctx.bezierCurveTo(
    59.727_624,
    51.679_247,
    56.955_259,
    51.308_84,
    56.214_513,
    51.402_032,
  );
  ctx.bezierCurveTo(
    55.473_773,
    51.495_022,
    54.735_194,
    51.633_868,
    54.460_129,
    52.881_296,
  );
  ctx.bezierCurveTo(
    53.420_489,
    57.587_859,
    48.768_108,
    69.322_751,
    43.468_126,
    75.640_741,
  );
  ctx.bezierCurveTo(
    43.271_019,
    75.640_741,
    43.069_626,
    75.626_481,
    42.876_825,
    75.633_641,
  );
  ctx.bezierCurveTo(
    42.876_825,
    75.633_641,
    40.236_578,
    75.126_788,
    38.300_255,
    80.091_092,
  );
  ctx.bezierCurveTo(
    36.359_599,
    85.053_189,
    37.4274,
    87.641_479,
    37.875_753,
    88.234_939,
  );
  ctx.bezierCurveTo(
    38.324_091,
    88.830_219,
    38.815_757,
    89.402_319,
    40.032_999,
    89.016_809,
  );
  ctx.bezierCurveTo(
    44.609_566,
    87.567_769,
    57.020_243,
    85.739_789,
    65.144_575,
    87.138_969,
  );
  ctx.bezierCurveTo(
    65.166_335,
    87.171_069,
    65.183_225,
    87.212_469,
    65.205_475,
    87.244_869,
  );
  ctx.bezierCurveTo(
    65.205_475,
    87.244_869,
    66.084_837,
    89.785_519,
    71.354_499,
    88.984_149,
  );
  ctx.bezierCurveTo(
    76.622_002,
    88.180_599,
    78.326_561,
    85.962_679,
    78.618_964,
    85.278_249,
  );
  ctx.bezierCurveTo(
    78.907_03,
    84.591_659,
    79.160_44,
    83.876_899,
    78.213_916,
    83.017_049,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("hanabiGreen", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-37.891_88, -52.702_171);
  ctx.moveTo(56.910_015, 58.292_302);
  ctx.bezierCurveTo(
    55.483_093,
    57.138_514,
    52.978_323,
    54.513_89,
    49.381_539,
    52.702_161,
  );
  ctx.bezierCurveTo(
    52.978_323,
    50.888_05,
    55.483_093,
    48.265_81,
    56.910_015,
    47.112_026,
  );
  ctx.bezierCurveTo(
    59.841_692,
    44.740_087,
    58.374_671,
    43.555_312,
    58.374_671,
    43.555_312,
  );
  ctx.bezierCurveTo(
    55.737_819,
    38.372_817,
    52.749_542,
    35.352_476,
    49.728_244,
    32.591_968,
  );
  ctx.bezierCurveTo(
    47.638_57,
    30.684_887,
    47.214_037,
    32.868_471,
    46.836_666,
    34.568_186,
  );
  ctx.bezierCurveTo(
    46.277_71,
    37.083_148,
    45.362_573,
    39.512_295,
    44.150_277,
    41.779_343,
  );
  ctx.bezierCurveTo(
    42.449_773,
    44.964_172,
    40.294_055,
    47.336_103,
    37.893_058,
    49.669_898,
  );
  ctx.bezierCurveTo(
    35.4897,
    47.336_103,
    33.333_993,
    44.964_172,
    31.633_476,
    41.779_343,
  );
  ctx.bezierCurveTo(
    30.423_545,
    39.512_295,
    29.506_07,
    37.083_148,
    28.949_451,
    34.568_186,
  );
  ctx.bezierCurveTo(
    28.569_708,
    32.868_496,
    28.145_185,
    30.684_887,
    26.055_515,
    32.591_968,
  );
  ctx.bezierCurveTo(
    23.034_217,
    35.352_476,
    20.045_939,
    38.372_817,
    17.409_083,
    43.555_312,
  );
  ctx.bezierCurveTo(
    17.409_083,
    43.555_312,
    15.942_073,
    44.740_087,
    18.873_743,
    47.112_026,
  );
  ctx.bezierCurveTo(
    20.303_023,
    48.265_81,
    22.807_798,
    50.888_05,
    26.402_219,
    52.702_161,
  );
  ctx.bezierCurveTo(
    22.807_798,
    54.513_89,
    20.303_023,
    57.138_514,
    18.873_743,
    58.292_302,
  );
  ctx.bezierCurveTo(
    15.942_073,
    60.661_853,
    17.409_083,
    61.846_624,
    17.409_083,
    61.846_624,
  );
  ctx.bezierCurveTo(
    20.045_939,
    67.031_506,
    23.034_217,
    70.051_852,
    26.055_515,
    72.812_352,
  );
  ctx.bezierCurveTo(
    28.145_185,
    74.719_433,
    28.569_729,
    72.535_849,
    28.949_451,
    70.836_141,
  );
  ctx.bezierCurveTo(
    29.506_055,
    68.321_168,
    30.423_545,
    65.892_021,
    31.633_476,
    63.622_593,
  );
  ctx.bezierCurveTo(
    33.333_993,
    60.440_152,
    35.4897,
    58.065_829,
    37.893_058,
    55.734_422,
  );
  ctx.bezierCurveTo(
    40.294_055,
    58.065_829,
    42.449_773,
    60.440_152,
    44.150_277,
    63.622_593,
  );
  ctx.bezierCurveTo(
    45.362_573,
    65.892_021,
    46.277_689,
    68.321_168,
    46.836_666,
    70.836_141,
  );
  ctx.bezierCurveTo(
    47.214_058,
    72.535_82,
    47.638_57,
    74.719_433,
    49.728_244,
    72.812_352,
  );
  ctx.bezierCurveTo(
    52.749_542,
    70.051_852,
    55.737_819,
    67.031_506,
    58.374_671,
    61.846_624,
  );
  ctx.bezierCurveTo(
    58.374_671,
    61.846_624,
    59.841_692,
    60.661_853,
    56.910_015,
    58.292_302,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("hanabiBlue", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-99.694_341, -76.779_331);
  ctx.moveTo(56.910_015, 58.292_302);
  ctx.moveTo(115.297_82, 63.128_745);
  ctx.bezierCurveTo(
    115.509_04,
    62.943_572,
    115.738_42,
    62.783_73,
    115.9409,
    62.592_635,
  );
  ctx.bezierCurveTo(
    116.136_78,
    62.405_678,
    117.7273,
    61.097_621,
    118.499_32,
    60.145_425,
  );
  ctx.bezierCurveTo(
    118.991_05,
    59.542_738,
    118.503_32,
    59.119_802,
    118.070_18,
    59.159_833,
  );
  ctx.bezierCurveTo(
    85.907_335,
    62.087_565,
    79.611_335,
    57.902_868,
    79.611_335,
    57.902_868,
  );
  ctx.bezierCurveTo(
    76.592_38,
    67.042_068,
    83.408_965,
    72.218_957,
    83.408_965,
    72.218_957,
  );
  ctx.bezierCurveTo(
    83.423_265,
    72.226_057,
    83.506_365,
    72.240_367,
    83.548_825,
    72.247_497,
  );
  ctx.bezierCurveTo(
    81.513_235,
    82.240_997,
    82.770_205,
    92.034_227,
    83.053_065,
    92.330_067,
  );
  ctx.bezierCurveTo(
    83.053_065,
    92.330_067,
    88.939_675,
    98.539_287,
    97.700_645,
    94.541_477,
  );
  ctx.bezierCurveTo(
    97.700_645,
    94.541_477,
    94.483_705,
    90.692_697,
    92.968_635,
    71.800_37,
  );
  ctx.bezierCurveTo(
    97.351_385,
    71.230_615,
    102.626_21,
    70.080_615,
    107.502_75,
    67.947_093,
  );
  ctx.bezierCurveTo(
    103.987_71,
    79.224_239,
    105.578_38,
    91.991_907,
    105.9032,
    92.330_067,
  );
  ctx.bezierCurveTo(
    105.9032,
    92.330_067,
    111.792_06,
    98.539_287,
    120.546_36,
    94.541_477,
  );
  ctx.bezierCurveTo(
    120.546_36,
    94.541_477,
    116.388_35,
    89.549_157,
    115.298_22,
    63.130_56,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("hanabiWhite", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(0.25, 0.25);
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-240.369_51, -310.428_58);
  ctx.moveTo(209.327_83, 329.846_36);
  ctx.bezierCurveTo(
    216.849_46,
    315.634_43,
    219.839_68,
    297.640_58,
    220.325_48,
    276.661_86,
  );
  ctx.bezierCurveTo(
    234.196_07,
    285.099_13,
    251.986_61,
    288.635_17,
    272.943_28,
    289.372_89,
  );
  ctx.bezierCurveTo(
    264.659_44,
    303.398_26,
    261.200_17,
    321.383_68,
    260.479_86,
    342.5574,
  );
  ctx.bezierCurveTo(
    246.935_91,
    334.289_74,
    229.664_68,
    330.702_83,
    209.327_83,
    329.846_36,
  );
  ctx.moveTo(304.8808, 333.662_26);
  ctx.bezierCurveTo(
    303.088_38,
    316.813_13,
    302.359_66,
    300.014_88,
    303.783_57,
    283.123_36,
  );
  ctx.bezierCurveTo(
    305.860_82,
    280.554_03,
    307.979_94,
    276.984_06,
    309.914_79,
    271.955_65,
  );
  ctx.bezierCurveTo(
    317.419_64,
    252.5287,
    313.290_28,
    242.403_97,
    311.556_46,
    240.0805,
  );
  ctx.bezierCurveTo(
    309.822_62,
    237.7486,
    307.921_29,
    235.509_97,
    303.205_62,
    237.019_35,
  );
  ctx.bezierCurveTo(
    290.6752,
    241.038_73,
    277.248_53,
    243.006_03,
    264.207_13,
    244.422_12,
  );
  ctx.bezierCurveTo(
    247.488_72,
    246.236_79,
    230.803_81,
    246.982_95,
    214.018_39,
    245.507_52,
  );
  ctx.bezierCurveTo(
    212.560_97,
    245.379_99,
    211.019_79,
    245.185_46,
    209.478_62,
    244.972_96,
  );
  ctx.bezierCurveTo(
    207.652_64,
    243.929_95,
    205.541_89,
    242.9039,
    203.054_25,
    241.920_28,
  );
  ctx.bezierCurveTo(
    183.864_91,
    234.330_97,
    173.864_02,
    238.511_45,
    171.569_01,
    240.266_75,
  );
  ctx.bezierCurveTo(
    169.265_59,
    242.022_04,
    167.054_35,
    243.946_94,
    168.545_27,
    248.712_52,
  );
  ctx.bezierCurveTo(
    172.515_48,
    261.398_08,
    174.4587,
    274.999_49,
    175.8575,
    288.185_38,
  );
  ctx.bezierCurveTo(
    177.541_05,
    304.084_79,
    178.219_52,
    319.950_26,
    177.105_49,
    335.875_09,
  );
  ctx.bezierCurveTo(
    174.978_02,
    338.452_91,
    172.808_64,
    342.065_26,
    170.823_55,
    347.212_42,
  );
  ctx.bezierCurveTo(
    163.318_66,
    366.639_36,
    167.448_03,
    376.772_61,
    169.1902,
    379.096_04,
  );
  ctx.bezierCurveTo(
    170.924_06,
    381.427_91,
    172.817_02,
    383.666_55,
    177.532_68,
    382.148_68,
  );
  ctx.bezierCurveTo(
    190.063_14,
    378.137_81,
    203.489_78,
    376.162_04,
    216.522_77,
    374.745_95,
  );
  ctx.bezierCurveTo(
    233.207_69,
    372.9398,
    249.850_72,
    372.202_04,
    266.577_53,
    373.660_55,
  );
  ctx.bezierCurveTo(
    269.123_82,
    375.780_47,
    272.666_88,
    377.9428,
    277.675_67,
    379.927_01,
  );
  ctx.bezierCurveTo(
    296.865_02,
    387.516_35,
    306.874_31,
    383.335_86,
    309.160_92,
    381.580_54,
  );
  ctx.bezierCurveTo(
    311.472_71,
    379.825_28,
    313.675_59,
    377.900_38,
    312.193_02,
    373.1348,
  );
  ctx.bezierCurveTo(
    308.214_45,
    360.449_21,
    306.271_23,
    346.8478,
    304.8808,
    333.6619,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
  ctx.scale(4, 4);
});

shapeFunctions.set("hanabiAltWhite", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-74.155_39, -82.208_377);
  ctx.transform(
    -3.299_668,
    1.133_672,
    -1.200_981,
    -3.114_738,
    480.922_54,
    360.609_04,
  );
  ctx.transform(0.282_222, 0, 0, 0.282_222, 74.155_397, 112.541_77);
  ctx.moveTo(28.347_656, 0.3125);
  ctx.bezierCurveTo(
    27.666_15,
    0.315_561,
    26.899_434,
    0.557_678,
    26.056_641,
    1.035_156,
  );
  ctx.bezierCurveTo(
    24.331_812,
    2.012_365,
    22.721_615,
    3.689_235,
    20.039_062,
    7.302_734,
  );
  ctx.bezierCurveTo(
    16.394_722,
    12.211_809,
    16.585_492,
    12.102_236,
    9.349_609,
    13.371_094,
  );
  ctx.bezierCurveTo(
    3.625_216,
    14.374_948,
    1.072_572,
    15.300_497,
    0.476_563,
    16.585_938,
  );
  ctx.bezierCurveTo(
    -0.119_634,
    17.871_768,
    0.743_099,
    19.083_487,
    5.179_688,
    23.181_641,
  );
  ctx.bezierCurveTo(
    8.308_318,
    26.071_597,
    9.246_576,
    27.098_502,
    9.529_297,
    27.947_266,
  );
  ctx.bezierCurveTo(
    9.727_531,
    28.542_435,
    10.004_965,
    31.058_07,
    10.146_484,
    33.537_109,
  );
  ctx.bezierCurveTo(
    10.434_059,
    38.574_062,
    10.889_828,
    40.396_472,
    12.142_578,
    41.515_625,
  );
  ctx.lineTo(12.142_578, 41.517_625);
  ctx.bezierCurveTo(
    13.038_688,
    42.318_129,
    14.019_224,
    42.435_651,
    15.210_938,
    41.882_859,
  );
  ctx.bezierCurveTo(
    16.474_513,
    41.296_867,
    19.547_233,
    38.243_507,
    22.160_156,
    34.978_563,
  );
  ctx.bezierCurveTo(
    24.800_264,
    31.679_638,
    26.328_861,
    30.274_372,
    27.921_875,
    29.679_735,
  );
  ctx.bezierCurveTo(
    28.475_513,
    29.473_089,
    30.772_06,
    28.951_228,
    33.025_391,
    28.521_531,
  );
  ctx.bezierCurveTo(
    38.227_387,
    27.529_583,
    39.684_318,
    27.140_709,
    40.884_766,
    26.418_016,
  );
  ctx.bezierCurveTo(
    43.257_594,
    24.989_461,
    42.659_074,
    23.724_034,
    37.429_688,
    19.119_188,
  );
  ctx.bezierCurveTo(
    35.287_418,
    17.232_72,
    33.308_731,
    15.289_797,
    33.033_203,
    14.802_734,
  );
  ctx.bezierCurveTo(
    32.651_245,
    14.127_522,
    32.480_019,
    12.921_518,
    32.308_594,
    9.722_656,
  );
  ctx.bezierCurveTo(
    32.011_232,
    4.173_873,
    31.555_509,
    2.353_547,
    30.128_906,
    1.017_578,
  );
  ctx.bezierCurveTo(
    29.626_019,
    0.545_574,
    29.029_163,
    0.309_439,
    28.347_656,
    0.3125,
  );
  ctx.moveTo(23.660_156, 14.164_062);
  ctx.bezierCurveTo(
    23.891_052,
    14.162_862,
    24.093_294,
    14.243_072,
    24.263_672,
    14.402_344,
  );
  ctx.bezierCurveTo(
    24.747_004,
    14.854_93,
    24.901_206,
    15.471_59,
    25.001_953,
    17.351_562,
  );
  ctx.bezierCurveTo(
    25.060_063,
    18.435_318,
    25.118_645,
    18.843_51,
    25.248_047,
    19.072_266,
  );
  ctx.bezierCurveTo(
    25.341_377,
    19.237_384,
    26.012_481,
    19.896_05,
    26.738_281,
    20.535_156,
  );
  ctx.bezierCurveTo(
    28.510_006,
    22.095_274,
    28.712_119,
    22.523_797,
    27.908_203,
    23.007_812,
  );
  ctx.bezierCurveTo(
    27.501_492,
    23.252_655,
    27.008_535,
    23.384_62,
    25.246_094,
    23.720_703,
  );
  ctx.bezierCurveTo(
    24.482_663,
    23.866_333,
    23.703_201,
    24.043_407,
    23.515_625,
    24.113_281,
  );
  ctx.bezierCurveTo(
    22.975_912,
    24.314_541,
    22.458_926,
    24.790_538,
    21.564_453,
    25.908_203,
  );
  ctx.bezierCurveTo(
    20.679_193,
    27.014_388,
    19.639_04,
    28.049_48,
    19.210_938,
    28.248_047,
  );
  ctx.bezierCurveTo(
    18.807_185,
    28.435_488,
    18.473_523,
    28.394_464,
    18.169_922,
    28.123_047,
  );
  ctx.bezierCurveTo(
    17.746_338,
    27.743_791,
    17.591_485,
    27.126_937,
    17.494_141,
    25.421_875,
  );
  ctx.bezierCurveTo(
    17.446_201,
    24.582_005,
    17.352_337,
    23.728_993,
    17.285_156,
    23.527_344,
  );
  ctx.bezierCurveTo(
    17.189_386,
    23.239_982,
    16.870_531,
    22.891_231,
    15.810_547,
    21.912_109,
  );
  ctx.bezierCurveTo(
    14.307_426,
    20.523_665,
    14.014_804,
    20.113_384,
    14.216_797,
    19.677_734,
  );
  ctx.bezierCurveTo(
    14.418_73,
    19.242_227,
    15.285_177,
    18.929_93,
    17.224_609,
    18.589_844,
  );
  ctx.bezierCurveTo(
    19.676_131,
    18.159_934,
    19.610_992,
    18.196_431,
    20.845_703,
    16.533_203,
  );
  ctx.bezierCurveTo(
    21.754_551,
    15.308_955,
    22.300_389,
    14.739_29,
    22.884_766,
    14.408_203,
  );
  ctx.bezierCurveTo(
    23.170_303,
    14.246_451,
    23.429_261,
    14.165_214,
    23.660_156,
    14.164_062,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("hanabiAltBlue", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-110.389_02, -72.869_975);
  ctx.moveTo(110.591_25, 92.432_703);
  ctx.bezierCurveTo(
    110.335_53,
    91.719_873,
    109.714_35,
    89.303_503,
    109.211_72,
    87.062_783,
  );
  ctx.bezierCurveTo(
    107.742_18,
    80.511_323,
    107.008_27,
    78.670_889,
    105.865_31,
    78.670_889,
  );
  ctx.bezierCurveTo(
    105.005_55,
    78.670_889,
    101.643_35,
    81.653_913,
    99.324_992,
    84.473_613,
  );
  ctx.bezierCurveTo(
    95.926_692,
    88.606_593,
    95.838_462,
    88.675_133,
    93.975_532,
    88.631_263,
  );
  ctx.bezierCurveTo(
    92.231_172,
    88.590_163,
    90.310_202,
    87.941_073,
    89.701_562,
    87.187_223,
  );
  ctx.bezierCurveTo(
    89.131_002,
    86.480_403,
    89.897_362,
    85.251_163,
    93.121_472,
    81.703_793,
  );
  ctx.bezierCurveTo(
    97.905_302,
    76.439_922,
    98.141_182,
    75.093_343,
    94.937_532,
    71.340_155,
  );
  ctx.bezierCurveTo(
    93.214_542,
    69.321_734,
    92.383_512,
    67.945_448,
    92.383_512,
    67.110_557,
  );
  ctx.bezierCurveTo(
    92.383_512,
    66.280_178,
    95.395_062,
    62.434_319,
    95.776_182,
    62.778_02,
  );
  ctx.bezierCurveTo(
    95.909_182,
    62.897_96,
    96.790_542,
    63.776_806,
    97.734_912,
    64.731_146,
  );
  ctx.bezierCurveTo(
    101.319_56,
    68.353_598,
    103.590_82,
    69.302_262,
    105.345_04,
    67.909_572,
  );
  ctx.bezierCurveTo(
    106.121_38,
    67.293_254,
    106.144_37,
    67.193_035,
    105.952_91,
    65.255_957,
  );
  ctx.bezierCurveTo(
    105.843_17,
    64.148_763,
    105.474_96,
    61.965_46,
    105.134_01,
    60.404_152,
  );
  ctx.bezierCurveTo(
    103.8133,
    54.355_863,
    104.761_23,
    52.011_272,
    108.527,
    52.011_272,
  );
  ctx.bezierCurveTo(
    109.624_47,
    52.011_272,
    109.888_35,
    52.120_274,
    110.3947,
    52.780_938,
  );
  ctx.bezierCurveTo(
    111.125_64,
    53.735_243,
    111.169_06,
    53.924_532,
    112.109_94,
    60.262_548,
  );
  ctx.bezierCurveTo(
    112.741_06,
    64.513_842,
    112.9829,
    65.512_454,
    113.476_39,
    65.904_165,
  );
  ctx.bezierCurveTo(
    114.762_89,
    66.925_529,
    116.967_89,
    65.447_542,
    121.179_64,
    60.740_508,
  );
  ctx.bezierCurveTo(
    125.207_39,
    56.239_247,
    126.422_93,
    55.508_035,
    128.496_35,
    56.339_188,
  );
  ctx.bezierCurveTo(
    129.871_37,
    56.890_453,
    131.251_18,
    57.930_871,
    131.2477,
    58.413_843,
  );
  ctx.bezierCurveTo(
    131.2467,
    58.625_498,
    129.861_46,
    60.430_251,
    128.170_62,
    62.423_316,
  );
  ctx.bezierCurveTo(
    124.784_15,
    66.414_798,
    123.959_78,
    67.936_204,
    123.981_36,
    70.154_646,
  );
  ctx.bezierCurveTo(
    123.995_56,
    71.758_322,
    124.419_28,
    72.583_018,
    126.591_04,
    75.250_64,
  );
  ctx.bezierCurveTo(
    128.107_91,
    77.113_707,
    128.206_25,
    78.221_674,
    127.022_41,
    80.107_833,
  );
  ctx.bezierCurveTo(
    126.098_03,
    81.580_483,
    125.338_23,
    82.027_523,
    124.421_42,
    81.637_993,
  );
  ctx.bezierCurveTo(
    124.085_97,
    81.495_103,
    122.830_83,
    80.446_963,
    121.632_78,
    79.308_273,
  );
  ctx.bezierCurveTo(
    119.011_73,
    76.816_96,
    118.077_77,
    76.202_402,
    116.912_78,
    76.202_402,
  );
  ctx.bezierCurveTo(
    115.314_39,
    76.202_402,
    114.918_52,
    77.084_076,
    114.977_54,
    80.512_803,
  );
  ctx.bezierCurveTo(
    115.005_94,
    82.147_213,
    115.257_98,
    84.428_633,
    115.537_17,
    85.582_623,
  );
  ctx.bezierCurveTo(
    116.427_16,
    89.254_553,
    116.499_62,
    90.696_633,
    115.824_69,
    91.302_373,
  );
  ctx.bezierCurveTo(
    115.195_06,
    91.867_403,
    111.732_71,
    93.728_673,
    111.311_27,
    93.728_673,
  );
  ctx.bezierCurveTo(
    111.171_62,
    93.728_673,
    110.847_76,
    93.145_433,
    110.591_46,
    92.432_703,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("moon", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(0.25, 0.25);
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.translate(-414.893_82, -203.536_19);
  ctx.moveTo(374.946_29, 141.086_53);
  ctx.bezierCurveTo(
    340.464_47,
    163.1565,
    330.404_13,
    209.013_71,
    352.476_55,
    243.488_28,
  );
  ctx.bezierCurveTo(
    374.549_45,
    277.965_45,
    420.410_39,
    288.024_39,
    454.892_22,
    265.955_19,
  );
  ctx.bezierCurveTo(
    477.025_72,
    251.7884,
    489.042_88,
    227.834_33,
    489.011_38,
    203.371_39,
  );
  ctx.bezierCurveTo(
    485.2623,
    214.509_46,
    477.9108,
    224.512_67,
    467.2415,
    231.3414,
  );
  ctx.bezierCurveTo(
    442.362_79,
    247.265_42,
    409.218_42,
    239.994_58,
    393.293_88,
    215.119_47,
  );
  ctx.bezierCurveTo(
    377.367_61,
    190.244_11,
    384.683_48,
    157.175_02,
    409.562_19,
    141.249_94,
  );
  ctx.bezierCurveTo(
    418.141_55,
    135.758_65,
    427.650_37,
    133.064_36,
    437.139_06,
    132.858_39,
  );
  ctx.bezierCurveTo(
    416.929_67,
    126.511_22,
    394.181_38,
    128.772_83,
    374.943_62,
    141.0852,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
  ctx.scale(4, 4);
});

shapeFunctions.set("hashtag", (ctx: CanvasRenderingContext2D) => {
  ctx.translate(0, 6);
  ctx.scale(1 / 3, 1 / 3);
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.moveTo(66.660_601, 59.539_431);
  ctx.moveTo(-15.043_961, -67.114_874);
  ctx.bezierCurveTo(
    -18.3138,
    -67.114_874,
    -21.483_707,
    -65.817_675,
    -23.969_688,
    -63.461_65,
  );
  ctx.bezierCurveTo(
    -26.452_065,
    -61.108_377,
    -27.975_591,
    -57.961_284,
    -28.261_528,
    -54.598_326,
  );
  ctx.lineTo(-29.398_805, -41.194_753);
  ctx.lineTo(-42.391_592, -41.194_753);
  ctx.bezierCurveTo(
    -45.661_435,
    -41.194_753,
    -48.831_339,
    -39.896_779,
    -51.317_317,
    -37.540_754,
  );
  ctx.bezierCurveTo(
    -53.799_696,
    -35.187_516,
    -55.324_118,
    -32.040_034,
    -55.609_163,
    -28.677_076,
  );
  ctx.bezierCurveTo(
    -55.905_037,
    -25.1886,
    -54.844_965,
    -21.891_788,
    -52.625_98,
    -19.395_357,
  );
  ctx.bezierCurveTo(
    -50.414_209,
    -16.908_205,
    -47.338_299,
    -15.538_687,
    -43.962_92,
    -15.538_687,
  );
  ctx.lineTo(-31.577_379, -15.538_687);
  ctx.lineTo(-32.857_175, -0.460_797);
  ctx.lineTo(-45.849_236, -0.460_797);
  ctx.bezierCurveTo(
    -49.119_082,
    -0.460_797,
    -52.288_623,
    0.836_437,
    -54.774_604,
    3.192_462,
  );
  ctx.bezierCurveTo(
    -57.256_979,
    5.545_735,
    -58.780_506,
    8.692_97,
    -59.066_446,
    12.055_01,
  );
  ctx.bezierCurveTo(
    -59.362_321,
    15.544_475,
    -58.303_512,
    18.840_299,
    -56.083_624,
    21.336_73,
  );
  ctx.bezierCurveTo(
    -53.872_76,
    23.823_847,
    -50.795_943,
    25.193_435,
    -47.420_567,
    25.193_435,
  );
  ctx.lineTo(-35.033_943, 25.193_435);
  ctx.lineTo(-36.1189, 37.975_907);
  ctx.bezierCurveTo(
    -36.414_775,
    41.464_418,
    -35.355_966,
    44.760_628,
    -33.136_078,
    47.257_978,
  );
  ctx.bezierCurveTo(
    -30.925_217,
    49.745_098,
    -27.848_401,
    51.114_648,
    -24.473_021,
    51.114_648,
  );
  ctx.bezierCurveTo(
    -21.203_175,
    51.114_648,
    -18.033_454,
    49.817_448,
    -15.548_373,
    47.461_388,
  );
  ctx.bezierCurveTo(
    -13.065_097,
    45.108_148,
    -11.540_491,
    41.960_878,
    -11.255_451,
    38.598_842,
  );
  ctx.bezierCurveTo(
    -11.253_651,
    38.581_202,
    -11.252_051,
    38.563_562,
    -11.251_251,
    38.545_922,
  );
  ctx.lineTo(-10.118_306, 25.193_326);
  ctx.lineTo(4.482_971, 25.194_526);
  ctx.lineTo(3.398_737, 37.975_093);
  ctx.bezierCurveTo(
    3.101_98,
    41.464_598,
    4.161_855,
    44.760_418,
    6.380_836,
    47.256_848,
  );
  ctx.bezierCurveTo(
    8.5917,
    49.744_888,
    11.668_513,
    51.114_648,
    15.0439,
    51.114_648,
  );
  ctx.bezierCurveTo(
    18.313_735,
    51.114_648,
    21.483_283,
    49.817_448,
    23.969_264,
    47.461_388,
  );
  ctx.bezierCurveTo(
    26.451_636,
    45.108_158,
    27.976_242,
    41.961_058,
    28.262_186,
    38.598_098,
  );
  ctx.bezierCurveTo(
    28.263_107,
    38.587_518,
    28.263_886,
    38.576_928,
    28.264_686,
    38.566_348,
  );
  ctx.lineTo(29.399_434, 25.195_514);
  ctx.lineTo(42.392_238, 25.195_514);
  ctx.bezierCurveTo(
    42.420_438,
    25.195_514,
    42.446_238,
    25.195_338,
    42.473_338,
    25.194_314,
  );
  ctx.bezierCurveTo(
    45.714_308,
    25.173_144,
    48.852_658,
    23.877_925,
    51.317_898,
    21.541_408,
  );
  ctx.bezierCurveTo(
    53.800_258,
    19.188_17,
    55.324_708,
    16.0409,
    55.609_758,
    12.678_859,
  );
  ctx.bezierCurveTo(
    55.905_738,
    9.190_383,
    54.846_418,
    5.894_135,
    52.626_558,
    3.396_752,
  );
  ctx.bezierCurveTo(
    50.415_668,
    0.909_635,
    47.338_888,
    -0.459_918,
    43.956_318,
    -0.459_918,
  );
  ctx.lineTo(31.576_863, -0.459_918);
  ctx.lineTo(32.857_019, -15.537_773);
  ctx.lineTo(45.848_718, -15.537_773);
  ctx.bezierCurveTo(
    49.118_578,
    -15.537_773,
    52.288_498,
    -16.835_783,
    54.774_448,
    -19.191_772,
  );
  ctx.bezierCurveTo(
    57.256_808,
    -21.544_128,
    58.781_228,
    -24.692_28,
    59.066_308,
    -28.054_321,
  );
  ctx.bezierCurveTo(
    59.362_288,
    -31.542_868,
    58.303_178,
    -34.838_904,
    56.084_168,
    -37.335_334,
  );
  ctx.bezierCurveTo(
    53.872_428,
    -39.823_369,
    50.795_438,
    -41.192_745,
    47.412_828,
    -41.192_745,
  );
  ctx.lineTo(35.034_535, -41.192_745);
  ctx.lineTo(36.118_762, -53.974_477);
  ctx.bezierCurveTo(
    36.415_448,
    -57.463_941,
    35.356_551,
    -60.759_765,
    33.136_659,
    -63.256_196,
  );
  ctx.bezierCurveTo(
    30.925_798,
    -65.743_313,
    27.848_985,
    -67.113_995,
    24.473_602,
    -67.113_995,
  );
  ctx.bezierCurveTo(
    21.203_756,
    -67.113_995,
    18.033_859,
    -65.816_02,
    15.547_875,
    -63.459_995,
  );
  ctx.bezierCurveTo(
    13.065_499,
    -61.107_674,
    11.541_073,
    -57.960_405,
    11.256_032,
    -54.597_447,
  );
  ctx.lineTo(10.117_675, -41.192_745);
  ctx.lineTo(-4.483_245, -41.192_745);
  ctx.lineTo(-3.398_285, -53.975_218);
  ctx.bezierCurveTo(
    -3.102_41,
    -57.463_729,
    -4.161_402,
    -60.759_942,
    -6.380_384,
    -63.257_289,
  );
  ctx.bezierCurveTo(
    -8.592_148,
    -65.744_442,
    -11.668_788,
    -67.113_995,
    -15.044_167,
    -67.113_995,
  );
  ctx.moveTo(-9.834_935, -22.679_328);
  ctx.lineTo(11.719_884, -22.679_328);
  ctx.bezierCurveTo(
    12.691_359,
    -22.679_328,
    13.617_742,
    -22.259_064,
    14.274_42,
    -21.520_842,
  );
  ctx.bezierCurveTo(
    14.930_188,
    -20.782_585,
    15.255_823,
    -19.794_984,
    15.171_032,
    -18.797_364,
  );
  ctx.lineTo(13.285_793, 3.420_074);
  ctx.bezierCurveTo(
    13.128_842,
    5.264_713,
    11.630_578,
    6.679_104,
    9.834_647,
    6.679_104,
  );
  ctx.lineTo(-11.720_175, 6.679_104);
  ctx.bezierCurveTo(
    -12.691_654,
    6.679_104,
    -13.618_037,
    6.259_581,
    -14.274_712,
    5.521_358,
  );
  ctx.bezierCurveTo(
    -14.930_483,
    4.783_101,
    -15.256_114,
    3.795_888,
    -15.171_328,
    2.798_268,
  );
  ctx.lineTo(-13.285_008, -19.420_263);
  ctx.bezierCurveTo(
    -13.128_058,
    -21.264_055,
    -11.629_97,
    -22.679_328,
    -9.834_935,
    -22.679_328,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
  ctx.scale(3, 3);
});

shapeFunctions.set("katakanaNu", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.transform(1, 0, 0, 1, -9.007_581, -241.9803);
  ctx.moveTo(-3, 222);
  ctx.bezierCurveTo(
    -4.390_911,
    222.140_16,
    -5.018_266,
    223.632_99,
    -4.794_479,
    225.270_79,
  );
  ctx.bezierCurveTo(
    -4.861_259,
    227.065_24,
    -4.975_042,
    229.190_11,
    -3.414_768,
    230.266_66,
  );
  ctx.bezierCurveTo(
    -2.003_446,
    230.945_52,
    -0.143_619,
    230.339_56,
    1.429_478,
    230.541_64,
  );
  ctx.bezierCurveTo(
    5.248_937,
    230.687_94,
    9.134_292,
    230.261_05,
    12.919_257,
    230.838_65,
  );
  ctx.bezierCurveTo(
    11.667_283,
    233.945_39,
    10.089_632,
    237.005_52,
    8.064_652,
    239.546_02,
  );
  ctx.bezierCurveTo(
    6.861_197,
    239.804_96,
    5.560_835,
    238.713_51,
    4.315_196,
    238.529_16,
  );
  ctx.bezierCurveTo(
    2.330_213,
    237.901_47,
    -0.4248,
    236.808_26,
    -1.8632,
    239.191_95,
  );
  ctx.bezierCurveTo(
    -2.867_403,
    240.922_51,
    -1.838_52,
    243.288_88,
    -0.177_014,
    243.883_49,
  );
  ctx.bezierCurveTo(
    0.849_583,
    244.537_51,
    2.229_81,
    244.751_12,
    3.100_987,
    245.5579,
  );
  ctx.bezierCurveTo(
    1.258_662,
    248.095_31,
    -1.541_109,
    249.563_24,
    -3.965_663,
    251.303_18,
  );
  ctx.bezierCurveTo(
    -6.163_495,
    252.945_83,
    -8.768_01,
    253.7252,
    -10.961_592,
    255.3244,
  );
  ctx.bezierCurveTo(
    -12.754_129,
    256.715_76,
    -11.735_929,
    259.802_05,
    -9.850_583,
    260.309_43,
  );
  ctx.bezierCurveTo(
    -7.236_942,
    260.353_13,
    -4.780_344,
    258.874_13,
    -2.328_94,
    257.988_96,
  );
  ctx.bezierCurveTo(
    2.091_71,
    256.100_39,
    6.117_787,
    253.239_49,
    9.950_07,
    250.151_75,
  );
  ctx.bezierCurveTo(
    11.712_105,
    249.671_87,
    12.937_119,
    251.9695,
    14.342_341,
    252.798_64,
  );
  ctx.bezierCurveTo(
    17.772_803,
    255.597_09,
    20.523_078,
    259.281_47,
    23.634_111,
    262.487_44,
  );
  ctx.bezierCurveTo(
    25.397_861,
    263.853_97,
    26.992_37,
    261.325_74,
    28.235_991,
    260.220_52,
  );
  ctx.bezierCurveTo(
    29.376_897,
    259.218_26,
    30.641_855,
    257.238_42,
    29.403_664,
    255.721_35,
  );
  ctx.bezierCurveTo(
    25.636_453,
    250.906_43,
    20.847_755,
    247.306_73,
    16.123_879,
    243.834_66,
  );
  ctx.bezierCurveTo(
    16.865_432,
    242.059_93,
    18.472_742,
    240.518_66,
    19.439_272,
    238.756_24,
  );
  ctx.bezierCurveTo(
    22.456_18,
    234.241_25,
    24.232_324,
    228.399_21,
    23.381_953,
    222.726_77,
  );
  ctx.bezierCurveTo(
    22.866_388,
    221.468_51,
    21.778_854,
    220.8183,
    20.555_392,
    221.174_73,
  );
  ctx.bezierCurveTo(
    12.638_927,
    221.186_73,
    4.707_749,
    221.131_03,
    -3.199_645,
    221.202_73,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

/** TODO */
shapeFunctions.set("katakanaHe", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);

  // Draw the curve for "ヘ" using bezierCurveTo.
  ctx.bezierCurveTo(-10, 200, 10, 240, -10, 260);

  /*
  ctx.lineTo(10, 260);
  ctx.bezierCurveTo(0, 240, -20, 200, 20, 220);

  ctx.lineTo(0, 220);
  */

  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set("emptySet", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(2 / 7, 2 / 7);
  ctx.beginPath();
  ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
  ctx.moveTo(18.066_96, -33.593_032);
  ctx.bezierCurveTo(
    17.937_116,
    -34.945_908,
    17.624_489,
    -35.265_144,
    15.303_533,
    -36.414_909,
  );
  ctx.bezierCurveTo(
    13.864_187,
    -37.127_948,
    10.308_861,
    -38.313_716,
    7.402_817,
    -39.049_953,
  );
  ctx.bezierCurveTo(
    -6.425_702,
    -42.553_351,
    -19.453_999,
    -39.314_305,
    -29.359_582,
    -29.910_263,
  );
  ctx.bezierCurveTo(
    -37.501_128,
    -22.180_935,
    -42.050_267,
    -10.684,
    -41.347_053,
    0.385_397,
  );
  ctx.bezierCurveTo(
    -41.013_532,
    5.635_746,
    -39.972_946,
    9.549_568,
    -37.152_261,
    16.163_069,
  );
  ctx.lineTo(-34.942_449, 21.344_279);
  ctx.lineTo(-8.364_535, -5.361_649);
  ctx.bezierCurveTo(
    17.139_857,
    -30.988_877,
    18.207_467,
    -32.129_19,
    18.066_96,
    -33.593_032,
  );
  ctx.moveTo(5.603_533, 40.114_884);
  ctx.bezierCurveTo(
    18.443_087,
    38.252_884,
    30.195_076,
    29.803_314,
    35.721_066,
    18.460_658,
  );
  ctx.bezierCurveTo(
    38.275_666,
    13.217_072,
    38.869_516,
    10.218_417,
    38.842_216,
    2.700_461,
  );
  ctx.bezierCurveTo(
    38.812_316,
    -5.501_334,
    37.619_886,
    -11.694_394,
    34.983_536,
    -17.338_568,
  );
  ctx.lineTo(33.192_096, -21.173_866);
  ctx.lineTo(19.886_003, -7.949_57);
  ctx.bezierCurveTo(
    5.092_643,
    6.752_854,
    -12.4197,
    24.558_808,
    -16.754_248,
    29.304_984,
  );
  ctx.bezierCurveTo(
    -18.576_124,
    31.299_864,
    -19.583_283,
    32.791_904,
    -19.583_283,
    33.495_994,
  );
  ctx.bezierCurveTo(
    -19.583_283,
    34.804_184,
    -18.010_991,
    36.027_254,
    -14.540_973,
    37.418_404,
  );
  ctx.bezierCurveTo(
    -7.406_745,
    40.278_564,
    -1.228_098,
    41.105_614,
    5.603_533,
    40.114_884,
  );
  ctx.moveTo(-70.961_668, 72.098_354);
  ctx.bezierCurveTo(
    -72.007_521,
    71.215_564,
    -72.848_172,
    69.767_224,
    -73.254_683,
    68.147_744,
  );
  ctx.bezierCurveTo(
    -74.310_054,
    63.943_354,
    -73.110_785,
    62.108_514,
    -61.628_224,
    50.359_954,
  );
  ctx.bezierCurveTo(
    -56.064_325,
    44.667_154,
    -51.512_034,
    39.823_104,
    -51.512_034,
    39.595_414,
  );
  ctx.bezierCurveTo(
    -51.512_034,
    39.367_754,
    -52.884_405,
    36.746_844,
    -54.561_735,
    33.771_244,
  );
  ctx.bezierCurveTo(
    -58.102_513,
    27.489_874,
    -61.680_206,
    17.244_693,
    -63.009_578,
    9.579_806,
  );
  ctx.bezierCurveTo(
    -65.363_319,
    -3.991_429,
    -61.413_282,
    -22.264_582,
    -53.589_285,
    -33.999_13,
  );
  ctx.bezierCurveTo(
    -50.286_636,
    -38.952_503,
    -42.060_615,
    -47.482_443,
    -36.981_141,
    -51.220_879,
  );
  ctx.bezierCurveTo(
    -21.607_703,
    -62.535_576,
    0.534_45,
    -65.806_397,
    19.745_235,
    -59.600_455,
  );
  ctx.bezierCurveTo(
    22.204_314,
    -58.806_068,
    27.589_996,
    -56.396_781,
    31.713_436,
    -54.246_479,
  );
  ctx.lineTo(39.210_586, -50.336_856);
  ctx.lineTo(44.284_316, -55.795_686);
  ctx.bezierCurveTo(
    50.534_776,
    -62.520_561,
    59.178_086,
    -70.298_349,
    62.013_226,
    -71.749_269,
  );
  ctx.bezierCurveTo(
    63.178_396,
    -72.345_549,
    64.837_846,
    -72.833_439,
    65.700_876,
    -72.833_439,
  );
  ctx.bezierCurveTo(
    69.748_316,
    -72.833_439,
    73.929_256,
    -67.197_129,
    72.560_576,
    -63.585_937,
  );
  ctx.bezierCurveTo(
    71.640_246,
    -61.157_693,
    66.840_296,
    -55.429_127,
    58.367_846,
    -46.647_432,
  );
  ctx.lineTo(50.817_656, -38.821_666);
  ctx.lineTo(54.663_266, -30.728_187);
  ctx.bezierCurveTo(
    59.200_806,
    -21.178_501,
    61.029_016,
    -15.9635,
    62.199_266,
    -9.231_772,
  );
  ctx.bezierCurveTo(
    64.012_526,
    1.198_819,
    62.785_926,
    11.912_655,
    58.527_336,
    22.841_222,
  );
  ctx.bezierCurveTo(
    48.469_396,
    48.652_304,
    26.793_386,
    63.400_694,
    -1.083_752,
    63.400_694,
  );
  ctx.bezierCurveTo(
    -12.913_562,
    63.400_694,
    -21.748_215,
    61.004_524,
    -33.291_158,
    54.665_244,
  );
  ctx.bezierCurveTo(
    -36.333_509,
    52.994_414,
    -39.085_355,
    51.627_384,
    -39.406_377,
    51.627_384,
  );
  ctx.bezierCurveTo(
    -39.727_406,
    51.627_384,
    -44.405_839,
    56.008_504,
    -49.802_924,
    61.363_214,
  );
  ctx.bezierCurveTo(
    -55.311_645,
    66.828_704,
    -60.647_873,
    71.623_884,
    -61.968_783,
    72.295_584,
  );
  ctx.bezierCurveTo(
    -65.257_998,
    73.968_204,
    -68.839_478,
    73.889_654,
    -70.961_668,
    72.098_364,
  );
  ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  ctx.closePath();
});

shapeFunctions.set(
  "infinity",
  (ctx: CanvasRenderingContext2D, colors?: readonly string[]) => {
    ctx.scale(1.2, 1.2);
    ctx.beginPath();
    ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
    ctx.translate(-91.082_135, -80.110_718);
    ctx.transform(2.128_652, 0, 0, 2.476_848, -78.229_708, -213.117_53);
    ctx.moveTo(84.443_554, 124.560_58);
    ctx.bezierCurveTo(
      86.918_78,
      124.464_38,
      89.392_846,
      122.020_23,
      89.392_11,
      117.589_85,
    );
    ctx.bezierCurveTo(
      89.392_11,
      117.082_88,
      88.432_933,
      116.691_02,
      87.515_841,
      116.429_29,
    );
    ctx.bezierCurveTo(
      86.129_485,
      111.085_71,
      80.894_815,
      111.014_25,
      78.815_855,
      115.1772,
    );
    ctx.bezierCurveTo(
      76.486_172,
      109.953_16,
      69.685_595,
      111.837_18,
      69.686_815,
      119.181_05,
    );
    ctx.bezierCurveTo(
      69.686_815,
      119.688_13,
      70.646_062,
      120.081_25,
      71.562_529,
      120.342_24,
    );
    ctx.bezierCurveTo(
      72.949_924,
      125.686_47,
      78.184_271,
      125.757_92,
      80.263_07,
      121.594_34,
    );
    ctx.bezierCurveTo(
      81.188_027,
      123.667_36,
      82.815_125,
      124.623_69,
      84.443_554,
      124.560_58,
    );
    ctx.moveTo(83.404_271, 120.906_82);
    ctx.bezierCurveTo(
      82.731_479,
      120.875_12,
      82.029_116,
      120.638_01,
      81.439_628,
      120.352_81,
    );
    ctx.bezierCurveTo(
      80.979_448,
      115.091_15,
      84.019_975,
      115.515_67,
      85.906_337,
      116.429_29,
    );
    ctx.bezierCurveTo(
      86.222_708,
      120.046_69,
      84.884_415,
      120.975_85,
      83.404_271,
      120.906_82,
    );
    ctx.moveTo(75.136_667, 120.896_22);
    ctx.bezierCurveTo(
      74.463_876,
      120.864_52,
      73.761_523,
      120.627_41,
      73.172_033,
      120.342_21,
    );
    ctx.bezierCurveTo(
      72.711_854,
      115.080_55,
      75.752_37,
      115.504_43,
      77.638_733,
      116.418_06,
    );
    ctx.bezierCurveTo(
      77.955_104,
      120.035_45,
      76.616_822,
      120.965_25,
      75.136_667,
      120.896_22,
    );
    ctx.transform(1 / 2.128_652, 0, 0, 1 / 2.476_848, 0, 0);
    ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
    ctx.closePath();
    ctx.scale(5 / 6, 5 / 6);
    if (colors !== undefined) {
      // When multiple colors are passed, create a gradient.
      const gradient = ctx.createLinearGradient(500, 0, 700, 0);
      for (const [i, color] of colors.entries()) {
        gradient.addColorStop(i / (colors.length - 1), color);
      }
      ctx.fillStyle = gradient;
      ctx.fill();
    }
  },
);

shapeFunctions.set(
  "rainbow",
  (ctx: CanvasRenderingContext2D, colors?: readonly string[]) => {
    ctx.scale(SCALE_FACTOR, SCALE_FACTOR);
    ctx.translate(-122.267_24, -111.175_04);

    // Yellow
    ctx.beginPath();
    ctx.moveTo(120.7045, 112.947_54);
    ctx.lineTo(121.467_55, 113.309_24);
    ctx.lineTo(121.3414, 113.713_76);
    ctx.bezierCurveTo(
      121.153_57,
      114.315_89,
      121.104_36,
      114.721_15,
      121.104_01,
      115.676_75,
    );
    ctx.bezierCurveTo(
      121.103_14,
      117.424_85,
      121.509_72,
      119.549_68,
      122.317_69,
      122.022_33,
    );
    ctx.bezierCurveTo(
      122.801_75,
      123.503_96,
      123.088_36,
      124.223_01,
      123.721_24,
      125.545_95,
    );
    ctx.bezierCurveTo(
      124.650_53,
      127.488_62,
      125.149_67,
      128.299_14,
      126.102_63,
      129.413_37,
    );
    ctx.bezierCurveTo(
      126.370_52,
      129.726_31,
      126.741_33,
      130.159_83,
      126.926_29,
      130.376_31,
    );
    ctx.bezierCurveTo(
      127.415_52,
      130.947_49,
      127.4699,
      131.134_59,
      127.200_76,
      131.314_24,
    );
    ctx.bezierCurveTo(
      126.711_53,
      131.640_58,
      123.8357,
      131.5418,
      120.8336,
      131.095_14,
    );
    ctx.bezierCurveTo(
      116.440_76,
      130.441_57,
      112.916_81,
      129.222_99,
      112.435_89,
      128.191_37,
    );
    ctx.bezierCurveTo(
      112.279_59,
      127.855_85,
      112.353_09,
      126.613_21,
      112.620_95,
      125.0753,
    );
    ctx.bezierCurveTo(
      113.304_71,
      121.143_78,
      115.386_25,
      116.9963,
      118.045_66,
      114.2666,
    );
    ctx.bezierCurveTo(
      118.602_19,
      113.695_41,
      119.833_22,
      112.584_33,
      119.908_67,
      112.585_32,
    );
    ctx.bezierCurveTo(
      119.925_97,
      112.585_56,
      120.285_31,
      112.748_66,
      120.704_91,
      112.947_79,
    );
    if (colors !== undefined) {
      ctx.fillStyle = colors[1]!;
      ctx.fill();
    }
    ctx.closePath();

    // Purple
    ctx.beginPath();
    ctx.moveTo(118.989_89, 90.936_255);
    ctx.bezierCurveTo(
      119.304_21,
      91.041_735,
      119.369_09,
      91.109_475,
      119.802_41,
      91.787_675,
    );
    ctx.bezierCurveTo(
      121.387_88,
      94.269_495,
      122.408,
      97.267_715,
      122.818_46,
      100.652_65,
    );
    ctx.bezierCurveTo(
      122.952_57,
      101.759_78,
      122.952_92,
      104.338_59,
      122.819_43,
      105.349_59,
    );
    ctx.bezierCurveTo(
      122.7068,
      106.202_06,
      122.515_16,
      107.140_97,
      122.326_32,
      107.771_96,
    );
    ctx.lineTo(122.187, 108.2371);
    ctx.lineTo(121.930_55, 108.2896);
    ctx.bezierCurveTo(
      121.178,
      108.452_94,
      120.443_93,
      108.579_59,
      120.384_56,
      108.556_66,
    );
    ctx.bezierCurveTo(
      120.345_76,
      108.542_46,
      120.286_86,
      108.397_91,
      120.250_45,
      108.235_97,
    );
    ctx.bezierCurveTo(
      119.925_73,
      106.801_33,
      118.037_06,
      104.644_61,
      115.188_27,
      102.455_36,
    );
    ctx.bezierCurveTo(
      112.247_36,
      100.195_28,
      108.836_67,
      98.496_875,
      106.756_57,
      98.256_765,
    );
    ctx.bezierCurveTo(
      105.978_51,
      98.167_165,
      105.387_61,
      97.957_585,
      105.267_41,
      97.729_075,
    );
    ctx.bezierCurveTo(
      105.074_04,
      97.360_785,
      105.669_28,
      96.739_035,
      107.188_93,
      95.723_645,
    );
    ctx.bezierCurveTo(
      110.261_11,
      93.670_755,
      114.325_17,
      91.716_045,
      116.863_79,
      91.070_105,
    );
    ctx.bezierCurveTo(
      117.650_79,
      90.869_705,
      118.613_07,
      90.808_665,
      118.989_64,
      90.934_275,
    );
    if (colors !== undefined) {
      ctx.fillStyle = colors[4]!;
      ctx.fill();
    }
    ctx.closePath();

    // Red
    ctx.beginPath();
    ctx.moveTo(109.895_01, 106.209_43);
    ctx.bezierCurveTo(
      112.598_28,
      106.441_23,
      115.232_04,
      107.157_59,
      117.396_17,
      108.249_98,
    );
    ctx.bezierCurveTo(
      118.112_17,
      108.6117,
      119.045_74,
      109.169_09,
      119.308_26,
      109.392_21,
    );
    ctx.lineTo(119.481_19, 109.538_97);
    ctx.lineTo(119.396_29, 110.419_39);
    ctx.bezierCurveTo(
      119.350_89,
      110.903_58,
      119.300_59,
      111.311_45,
      119.287_82,
      111.325_81,
    );
    ctx.bezierCurveTo(
      119.273_92,
      111.340_01,
      118.942_99,
      111.372_01,
      118.550_93,
      111.391_81,
    );
    ctx.bezierCurveTo(
      117.515_81,
      111.444_31,
      116.911_73,
      111.632_42,
      115.457_47,
      112.342_47,
    );
    ctx.bezierCurveTo(
      112.806_01,
      113.637_47,
      109.673_07,
      116.012_57,
      107.539_51,
      118.345_08,
    );
    ctx.bezierCurveTo(
      106.571_78,
      119.403_04,
      106.039_65,
      120.152_22,
      105.409_36,
      121.344_05,
    );
    ctx.bezierCurveTo(
      104.742_76,
      122.604_37,
      104.485_37,
      122.824_76,
      104.131_06,
      122.438_62,
    );
    ctx.bezierCurveTo(
      103.598_47,
      121.858_26,
      102.938_22,
      119.465_55,
      102.378_67,
      116.089_09,
    );
    ctx.bezierCurveTo(
      101.960_58,
      113.564_93,
      101.900_42,
      112.892_63,
      101.897_82,
      110.696_79,
    );
    ctx.bezierCurveTo(
      101.895_82,
      108.890_12,
      101.900_82,
      108.741_24,
      102.014_96,
      108.2899,
    );
    ctx.bezierCurveTo(
      102.158_43,
      107.709_46,
      102.343_84,
      107.321_48,
      102.568_27,
      107.129_11,
    );
    ctx.bezierCurveTo(
      102.855_56,
      106.882_85,
      104.280_19,
      106.522_36,
      105.7631,
      106.320_83,
    );
    ctx.bezierCurveTo(
      106.706_17,
      106.192_41,
      108.973_62,
      106.132_43,
      109.895_77,
      106.211_46,
    );
    if (colors !== undefined) {
      ctx.fillStyle = colors[0]!;
      ctx.fill();
    }
    ctx.closePath();

    // Blue
    ctx.beginPath();
    ctx.moveTo(129.447_17, 90.861_735);
    ctx.bezierCurveTo(
      130.388_35,
      91.250_415,
      132.5268,
      93.071_585,
      134.783_09,
      95.405_515,
    );
    ctx.bezierCurveTo(
      137.747_53,
      98.471_965,
      139.321_58,
      100.778_56,
      139.321_58,
      102.0561,
    );
    ctx.bezierCurveTo(
      139.321_58,
      102.333_05,
      139.304_28,
      102.373_26,
      138.950_77,
      102.834_41,
    );
    ctx.bezierCurveTo(
      137.180_25,
      105.157_06,
      134.363_65,
      107.322_91,
      131.309_42,
      108.710_27,
    );
    ctx.bezierCurveTo(
      129.060_72,
      109.731_74,
      127.140_08,
      110.2505,
      125.084_42,
      110.391_52,
    );
    ctx.bezierCurveTo(
      124.641_13,
      110.423_32,
      124.273_44,
      110.437_72,
      124.267_68,
      110.431_02,
    );
    ctx.bezierCurveTo(
      124.190_08,
      110.3287,
      123.377_73,
      108.854_59,
      123.378_77,
      108.8175,
    );
    ctx.bezierCurveTo(
      123.3795,
      108.7928,
      123.505_61,
      108.679_92,
      123.658_78,
      108.573_72,
    );
    ctx.bezierCurveTo(
      124.340_67,
      108.1033,
      124.877_97,
      107.458_62,
      125.544_74,
      106.311_52,
    );
    ctx.bezierCurveTo(
      126.946_77,
      103.899_48,
      128.280_38,
      99.923_625,
      128.7263,
      96.826_095,
    );
    ctx.bezierCurveTo(
      128.979_98,
      95.063_385,
      128.991_76,
      94.014_185,
      128.7779,
      92.039_045,
    );
    ctx.bezierCurveTo(
      128.7131,
      91.440_205,
      128.7391,
      90.948_825,
      128.8361,
      90.824_745,
    );
    ctx.bezierCurveTo(
      128.9228,
      90.718_545,
      129.131_72,
      90.730_545,
      129.444_42,
      90.856_545,
    );
    if (colors !== undefined) {
      ctx.fillStyle = colors[3]!;
      ctx.fill();
    }
    ctx.closePath();

    // Green
    ctx.beginPath();
    ctx.moveTo(142.647_93, 111.570_55);
    ctx.bezierCurveTo(
      142.947_36,
      112.036_36,
      142.445_89,
      113.716_33,
      141.134_21,
      116.637_04,
    );
    ctx.bezierCurveTo(
      139.036_09,
      121.309_35,
      136.6023,
      124.828_13,
      135.2139,
      125.196_63,
    );
    ctx.bezierCurveTo(
      134.984_82,
      125.255_83,
      134.934_91,
      125.249_13,
      134.349_06,
      125.029_41,
    );
    ctx.bezierCurveTo(
      130.466_84,
      123.581_04,
      126.571_73,
      120.233_38,
      124.279_71,
      116.375_26,
    );
    ctx.bezierCurveTo(
      123.695_97,
      115.392_36,
      123.062_92,
      114.006_44,
      122.815_96,
      113.169_63,
    );
    ctx.bezierCurveTo(
      122.791_66,
      113.082_23,
      122.918_19,
      112.913_49,
      123.421_68,
      112.373_88,
    );
    ctx.lineTo(124.059_75, 111.6892);
    ctx.lineTo(124.380_67, 111.942_87);
    ctx.bezierCurveTo(
      125.369_56,
      112.725_24,
      127.163_57,
      113.223_22,
      129.9172,
      113.479_75,
    );
    ctx.bezierCurveTo(
      130.856_05,
      113.567_15,
      133.7227,
      113.566_85,
      134.700_58,
      113.479,
    );
    ctx.bezierCurveTo(
      137.556_26,
      113.222_87,
      139.344_97,
      112.778_65,
      141.095_71,
      111.891_04,
    );
    ctx.bezierCurveTo(
      141.775_96,
      111.546,
      141.980_05,
      111.466_79,
      142.239_03,
      111.447_71,
    );
    ctx.bezierCurveTo(
      142.532_22,
      111.430_11,
      142.560_64,
      111.433_51,
      142.648,
      111.570_48,
    );
    if (colors !== undefined) {
      ctx.fillStyle = colors[2]!;
      ctx.fill();
    }
    ctx.closePath();

    // Center
    ctx.beginPath();
    ctx.moveTo(121.056_85, 112.245);
    ctx.lineTo(120.182_83, 111.841_47);
    ctx.lineTo(120.098_93, 111.683_76);
    ctx.lineTo(120.015_03, 111.526_06);
    ctx.lineTo(120.095_13, 110.657_95);
    ctx.bezierCurveTo(
      120.200_82,
      109.512_76,
      120.191_43,
      109.565_68,
      120.311_03,
      109.425_97,
    );
    ctx.lineTo(120.412_91, 109.306_72);
    ctx.lineTo(121.366_19, 109.116_56);
    ctx.lineTo(122.3195, 108.9264);
    ctx.lineTo(122.440_44, 108.9861);
    ctx.bezierCurveTo(
      122.506_94,
      109.0179,
      122.579_41,
      109.0549,
      122.601_24,
      109.0662,
    );
    ctx.bezierCurveTo(
      122.622_04,
      109.0768,
      122.867_75,
      109.478_88,
      123.144_95,
      109.958_16,
    );
    ctx.lineTo(123.648_74, 110.829_58);
    ctx.lineTo(123.631_44, 110.986_23);
    ctx.bezierCurveTo(
      123.614_14,
      111.1411,
      123.607_14,
      111.150_63,
      122.945_46,
      111.861_89,
    );
    ctx.bezierCurveTo(
      122.359_67,
      112.492_48,
      122.263_65,
      112.583_46,
      122.1645,
      112.601_78,
    );
    ctx.bezierCurveTo(
      122.1025,
      112.612_38,
      122.024_49,
      112.629_98,
      121.991_56,
      112.637_08,
    );
    ctx.bezierCurveTo(
      121.956_56,
      112.644_08,
      121.5644,
      112.480_79,
      121.057_46,
      112.246_63,
    );
    if (colors !== undefined) {
      ctx.fillStyle = "#000000";
      ctx.fill();
    }
    ctx.closePath();

    // External path
    ctx.beginPath();
    ctx.moveTo(143.094_54, 111.199_23);
    ctx.bezierCurveTo(
      142.981_22,
      111.042_58,
      142.755_96,
      110.850_66,
      142.345_53,
      110.850_66,
    );
    ctx.bezierCurveTo(
      141.664_93,
      110.850_66,
      140.830_18,
      111.390_83,
      140.810_91,
      111.404_02,
    );
    ctx.bezierCurveTo(
      139.036_65,
      112.404_09,
      135.849_82,
      112.998_91,
      132.292_74,
      112.998_91,
    );
    ctx.bezierCurveTo(
      128.761_34,
      112.998_91,
      125.711_47,
      112.388_84,
      124.7056,
      111.480_23,
    );
    ctx.bezierCurveTo(
      124.517_07,
      111.310_53,
      124.339_32,
      111.163_77,
      124.168_47,
      111.042_16,
    );
    ctx.bezierCurveTo(
      124.199_67,
      111.042_16,
      124.233_27,
      111.049_16,
      124.266_87,
      111.049_16,
    );
    ctx.bezierCurveTo(
      129.705_23,
      111.049_16,
      136.348_57,
      107.523_82,
      139.713_07,
      102.850_18,
    );
    ctx.bezierCurveTo(
      139.933_82,
      102.545,
      140.004_53,
      102.144_28,
      139.924_47,
      101.658_34,
    );
    ctx.bezierCurveTo(
      139.436_55,
      98.710_385,
      133.157_03,
      92.616_215,
      130.637_96,
      90.833_955,
    );
    ctx.bezierCurveTo(
      129.505_76,
      90.032_105,
      128.739_52,
      89.938_435,
      128.339_38,
      90.461_075,
    );
    ctx.bezierCurveTo(
      127.879_23,
      91.075_735,
      128.183_09,
      92.332_695,
      128.217_05,
      92.456_845,
    );
    ctx.bezierCurveTo(
      129.086_04,
      97.041_095,
      125.749_36,
      106.619_27,
      123.318,
      108.074_75,
    );
    ctx.bezierCurveTo(
      123.078_53,
      108.218_35,
      122.874_76,
      108.359_83,
      122.695_48,
      108.501_65,
    );
    ctx.bezierCurveTo(
      124.441_95,
      103.276_82,
      123.329_02,
      95.751_145,
      119.883_18,
      90.883_595,
    );
    ctx.bezierCurveTo(
      119.596_57,
      90.480_445,
      119.082_74,
      90.278_155,
      118.355_04,
      90.278_155,
    );
    ctx.bezierCurveTo(
      115.146_81,
      90.278_155,
      108.096_85,
      94.224_045,
      105.858_17,
      95.960_535,
    );
    ctx.bezierCurveTo(
      104.912_11,
      96.690_485,
      104.531_17,
      97.304_885,
      104.691_69,
      97.840_905,
    );
    ctx.bezierCurveTo(
      104.941_91,
      98.686_325,
      106.468_11,
      98.801_755,
      106.626_52,
      98.808_365,
    );
    ctx.bezierCurveTo(
      111.174_54,
      99.381_485,
      119.095_63,
      105.569_22,
      119.705_54,
      108.371_19,
    );
    ctx.bezierCurveTo(
      119.763_74,
      108.632_61,
      119.833_76,
      108.846_19,
      119.906_89,
      109.057_52,
    );
    ctx.bezierCurveTo(
      117.013_28,
      106.8874,
      112.640_79,
      105.538_71,
      108.272_49,
      105.538_71,
    );
    ctx.bezierCurveTo(
      106.228_53,
      105.538_71,
      104.3152,
      105.823_77,
      102.5837,
      106.3928,
    );
    ctx.bezierCurveTo(
      102.228_38,
      106.510_64,
      101.939_14,
      106.791_33,
      101.725_49,
      107.231_63,
    );
    ctx.bezierCurveTo(
      100.419_88,
      109.900_72,
      101.9861,
      118.587_71,
      102.998_88,
      121.54,
    );
    ctx.bezierCurveTo(
      103.394_95,
      122.688_27,
      103.855_01,
      123.250_42,
      104.413_59,
      123.250_42,
    );
    ctx.bezierCurveTo(
      105.2719,
      123.250_42,
      105.841_19,
      121.823_27,
      105.896_81,
      121.677_31,
    );
    ctx.bezierCurveTo(
      107.780_24,
      117.574_56,
      115.664_94,
      111.948_78,
      118.676_23,
      111.948_78,
    );
    ctx.bezierCurveTo(
      118.742_73,
      111.948_78,
      118.806_88,
      111.951_78,
      118.868_91,
      111.955_78,
    );
    ctx.bezierCurveTo(
      119.104_57,
      111.973_38,
      119.316_08,
      111.987_58,
      119.506_99,
      111.987_58,
    );
    ctx.bezierCurveTo(
      119.558_59,
      111.987_58,
      119.585_69,
      111.969_98,
      119.635_22,
      111.969_98,
    );
    ctx.bezierCurveTo(
      115.187_73,
      115.140_22,
      111.722_65,
      121.896_79,
      111.690_56,
      127.905_97,
    );
    ctx.bezierCurveTo(
      111.688_56,
      128.2762,
      111.855_52,
      128.638_07,
      112.191_45,
      128.975_77,
    );
    ctx.bezierCurveTo(
      114.246_11,
      131.078_41,
      122.712_95,
      132.213_58,
      125.717_83,
      132.213_58,
    );
    ctx.lineTo(125.963_88, 132.211_58);
    ctx.bezierCurveTo(
      127.151_66,
      132.186_88,
      127.815_19,
      131.919_81,
      127.994_94,
      131.387_99,
    );
    ctx.bezierCurveTo(
      128.279_81,
      130.553_51,
      127.1132,
      129.549_05,
      126.993_32,
      129.446_69,
    );
    ctx.bezierCurveTo(
      123.648_09,
      126.259_02,
      120.822_94,
      116.510_98,
      121.950_91,
      113.876_78,
    );
    ctx.bezierCurveTo(
      122.064_23,
      113.617_11,
      122.150_18,
      113.382,
      122.214_29,
      113.155_62,
    );
    ctx.bezierCurveTo(
      123.808_76,
      118.432_66,
      129.048_12,
      123.864_51,
      134.649_16,
      125.744_85,
    );
    ctx.bezierCurveTo(
      134.766_64,
      125.784_35,
      134.895_21,
      125.804_05,
      135.0258,
      125.804_05,
    );
    ctx.bezierCurveTo(
      137.763_21,
      125.804_05,
      142.174_24,
      116.278_16,
      143.077_43,
      113.249_64,
    );
    ctx.bezierCurveTo(
      143.372_69,
      112.256_07,
      143.379_28,
      111.584_97,
      143.094_73,
      111.197_17,
    );
    ctx.closePath();
    ctx.scale(1 / SCALE_FACTOR, 1 / SCALE_FACTOR);
  },
);

shapeFunctions.set("triangle", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(150 / 42, 150 / 42);
  ctx.beginPath();
  ctx.transform(1, 0, 0, 1, 16.9774, -22.3191);
  ctx.moveTo(-16.977_913, 37.727_61);
  ctx.lineTo(-34.930_431, 6.631_814);
  ctx.lineTo(0.975_572, 6.632_296);
  ctx.closePath();
  ctx.moveTo(-16.997_733, 26.070_432);
  ctx.lineTo(-9.120_09, 12.425_835);
  ctx.lineTo(-24.875_376, 12.425_835);
  ctx.closePath();
  ctx.transform(1, 0, 0, 1, -16.9774, 22.3191);
  ctx.scale(42 / 150, 42 / 150);
});

export function drawPip(
  ctx: CanvasRenderingContext2D,
  suit: Suit,
  secondary?: boolean,
  shadow?: boolean,
  customFill?: string,
  lineWidth?: number,
  highLight?: boolean,
): void {
  // Positive indication for Color pips.
  if (highLight === true) {
    ctx.save();
    ctx.beginPath();
    ctx.arc(3, 5, 100, 0, 360);
    ctx.fillStyle = "rgba(238, 188, 29, 0.3)";
    ctx.fill();
    ctx.stroke();
    ctx.restore();
  }

  // Each suit has a shape defined in the "suits.json" file (as the 'pip' property).
  const shapeFunction = shapeFunctions.get(suit.pip);
  assertDefined(
    shapeFunction,
    `Failed to find the shape function for pip "${suit.pip}".`,
  );

  // Handle the shadow
  if (shadow === true) {
    ctx.shadowColor = "rgba(0, 0, 0, 0.9)";
  }

  const hasCustomFill = customFill !== undefined && customFill !== "";
  if (secondary === true) {
    ctx.scale(0.8, 0.8);

    ctx.save();
    ctx.beginPath();
    ctx.ellipse(0, 0, 80, 80, 0, 0, 2 * Math.PI);
    ctx.moveTo(90, 0);
    ctx.ellipse(0, 0, 90, 90, 0, 0, 2 * Math.PI);

    if (hasCustomFill) {
      ctx.fillStyle = customFill!;
    } else if (suit.fill === "multi") {
      const colors = suit.fillColors;
      const grad = ctx.createLinearGradient(0, -100, 30, 100);
      for (const [i, color] of colors.entries()) {
        grad.addColorStop(i / (colors.length - 1), color);
      }
      ctx.fillStyle = grad;
    } else {
      ctx.fillStyle = suit.fill;
    }

    // Workaround for Konva.Shape's Context not supporting `evenodd`. This is used in e.g. Empathy
    // view.
    interface KonvaCanvas {
      _context: CanvasRenderingContext2D | undefined;
    }
    const konvaCanvas = ctx as unknown as KonvaCanvas;

    const canvasFillRule = "evenodd";
    const context = konvaCanvas._context;
    if (context === undefined) {
      ctx.fill(canvasFillRule);
    } else {
      context.fill(canvasFillRule);
    }

    ctx.lineWidth = 1;
    ctx.strokeStyle = "black";
    ctx.stroke();
    ctx.restore();
  }

  if (hasCustomFill) {
    // The parent function has specified a custom fill color.
    ctx.fillStyle = customFill!;
    shapeFunction(ctx);
    ctx.fill();
  } else if (suit.fill === "multi") {
    // Rainbow and omni cards have a multiple color fill which is passed as an array to the drawing
    // function; the drawing function will handle the filling.
    shapeFunction(ctx, suit.fillColors);
  } else {
    // All other suits have a solid fill.
    ctx.fillStyle = suit.fill;
    shapeFunction(ctx);
    ctx.fill();
  }

  // Draw a black border around the shape.
  if (shadow === true) {
    ctx.lineWidth = lineWidth ?? (hasCustomFill ? 8 : 5);
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
  } else {
    ctx.lineWidth = 3;
  }
  ctx.stroke();
}
